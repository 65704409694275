import React, { useEffect, useState } from "react";
import loadable from "@loadable/component";
import Navbar from "@organism/Navbar/Navbar";
import Footer from "@organism/Footer/Footer";
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next";
import SEO from "../../components/Seo";
import ScrollTopButton from "@molecules/ScrollTopButton";
import { isBrowser } from "@utils/isBrowser";
import FloatingSeeDemo from "@molecules/FloatingSeeDemo";
import PipeDriveChat from "@utils/pipedriveChat";
const OurOffices = loadable(() => import("@organism/OurOffices"));
const JoinUs = loadable(() => import("@organism/JoinUs"));
const HowIsIt = loadable(() => import("@organism/HowIsIt"));
const OurTeam = loadable(() => import("@organism/OurTeam"));

const Work = ({ location }) => {
  const { t } = useTranslation();
  const { language } = useI18next();
  const [chatStyle, setChatStyle] = useState(`textarea[class^="inputField_"]
  {
    color: #FFFFFF !important; 
     -webkit-text-fill-color: #FFFFFF !important;
  }`);
  const [clientChat, setClientChat] = useState(false);
  const [initChat, setinitChat] = useState(false);

  const handleOnLoad = () => {
    setTimeout(() => {
      setChatStyle(`textarea[class^="inputField_"]
      {
        color:white!important; 
         -webkit-text-fill-color: white !important;
      } `);
    }, 2500);
  };
  const openChat = () => {
    setinitChat(true);

    setClientChat(t("JIVOCHAT"));
    handleOnLoad();
  };

  useEffect(() => {
    if (initChat) {      
      if (
        isBrowser &&
        clientChat !== false &&
        clientChat !== t("JIVOCHAT")
      ) {
        // window.location.reload();
        setChatStyle(`textarea[class^="inputField_"]
        {
          color:white!important; 
           -webkit-text-fill-color: white !important;
        } `);
      }
    }
  });
  return (
    <>
      <SEO 
        title={t('SEO.WORK.TITLE')} 
        description={t('SEO.WORK.DESCRIPTION')}
        originUrl={location.origin}
        siteUrl={location.href}
        lang={language}
      />
      {/* <CustomCursor /> */}
      <Navbar location={location} />
      <OurTeam />
      <HowIsIt />
      <OurOffices />
      <JoinUs />
      {/* <Chat
        chatStyle={chatStyle}
        clientChat={clientChat}
        initChat={initChat}
        openChat={openChat}
      /> */}
      <PipeDriveChat />
      <ScrollTopButton />
      <FloatingSeeDemo />
      <Footer openChat={openChat} />
    </>
  );
};

export default Work;
